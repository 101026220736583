import React, { useEffect, useState } from "react";
import { useToken } from "../hooks/useToken";
import { infoUsuario } from "../http/requestUsers";

const UsuarioContext = React.createContext();

export function UsuarioProvider(props){
    
    const { token, setToken } = useToken();
    const [infoBasicData, setInfoBasicData] = useState();
    const [spinnerLoad, setSpinnerLoad]=useState(false);
    const [darkMode, setDarkMode]=useState(false);

    const loadData=()=>{
        setSpinnerLoad(true);
        infoUsuario().then(
          data =>{
            if(data!=null ){
            console.log(data);
                setInfoBasicData(data);
            }
            setSpinnerLoad(false);
          }
        )
    }
    useEffect(()=>{
        if(token){
            loadData();
        }
        
      },[token]);

      const value = React.useMemo(()=>{
        return({
            token,
            infoBasicData,
            spinnerLoad,
            darkMode,
            setToken,
            setSpinnerLoad,
            setDarkMode,
            loadData
        });
      },[darkMode,token,infoBasicData,spinnerLoad]);

      return <UsuarioContext.Provider value={value} {...props} />
}

export function useUsuario(){
    const context = React.useContext(UsuarioContext);
    if(!context){
        throw new Error('useUsuario debe estar dentro del proveedor UsuarioContext');
    }
    return context;
}