import axios from "axios";
import { toastError } from "../alerts/toasAlerts";
import { infoUsuario_url, listUsersAllAdmin_url, nuevoUsuario_url } from "../config/urls/urlUsuarios";
import { infoCodTK } from "../functions/infoTk";
import { verificarCode } from "../functions/verificResponse"

export async function listUsers() {

    return axios.get(listUsersAllAdmin_url, {
      headers: {
        'Authorization': `Bearer ${infoCodTK()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      //console.log(data.data);
      if (data.data.ok && data.status === 200) {
        return data.data.data;
      } else {
        toastError(data.data.mensaje);
        verificarCode(data.data.status);
        console.log("Conexion fallida");
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      //mostrarAlerta("Ocurrió un error al conectar con el servidor");
    });
  
  }
  
export async function nuevoUsuario(params) {

    return axios.post(nuevoUsuario_url,params, {
      headers: {
        'Authorization': `Bearer ${infoCodTK()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      //console.log(data.data);
      if (data.data.ok && data.status === 200) {
        return data.data.data;
      } else {
        toastError(data.data.mensaje);
        verificarCode(data.data.status);
        console.log("Conexion fallida");
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      //mostrarAlerta("Ocurrió un error al conectar con el servidor");
    });
  
  }


export async function infoUsuario() {

    return axios.post(infoUsuario_url,{}, {
      headers: {
        'Authorization': `Bearer ${infoCodTK()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      //console.log(data.data);
      if (data.data.ok && data.status === 200) {
        return data.data.data;
      } else {
        toastError(data.data.mensaje);
        verificarCode(data.data.status);
        console.log("Conexion fallida");
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      //mostrarAlerta("Ocurrió un error al conectar con el servidor");
    });
  
  }