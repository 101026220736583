import axios from "axios";
import { toastError } from "../alerts/toasAlerts";
import { infoCodTK } from "../functions/infoTk";
import { verificarCode } from "../functions/verificResponse"
import { list_pharmas_url } from "../config/urls/urlBusiness";

export async function ListaFarmacias(mes) {

    return axios.get(list_pharmas_url ,{
      headers: {
        'Authorization': `Bearer ${infoCodTK()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      //console.log(data.data);
      if (data.data.ok && data.status === 200) {
        return data.data.data;
      } else {
        toastError(data.data.mensaje);
        verificarCode(data.data.status);
        console.log("Conexion fallida");
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      //mostrarAlerta("Ocurrió un error al conectar con el servidor");
    });
  
  }
  
