import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { toastError, toastSuccess } from "../../../alerts/toasAlerts";
import { edithEnlace, nuevoEnlace } from "../../../http/requestLink";
import { AiFillEdit } from "react-icons/ai";
import { listUsersCliente } from "../../../http/requestUserClient";

const AdminUsersClient = () => {
  const [modalNew, setModalNew] = useState(false);
  const [modalEdith, setModalEdith] = useState(false);
  const [dataModalEdith, setDataModalEdith] = useState(false);

  const [data, setData] = useState([]);
  const [usuarios, setUsuarios] = useState([]);

  const loadUsuarios = () => {
    listUsersCliente().then((data) => {
      console.log(data);
      if (data != null) {
        setUsuarios(data);
      }
    });
  };

  useEffect(() => {
    loadUsuarios();
  }, []);

  const handleCopy = async (textToCopy) => {
    console.log("ingresa");
    try {
      await navigator.clipboard.writeText(textToCopy);
      toastSuccess("Codigo Copiado");
    } catch (err) {
      console.error("Error al copiar:", err);
      toastError("Error al copiar");
    }
  };

  const listItems = usuarios.map((d, index) => (
    <tr key={index.toString()}>
      <td>{index + 1}</td>
      <td>{d.mail ?? ""}</td>
      <td className="fst-italic" style={{ fontSize: "0.8rem" }}>
        <button
          className="btn btn-light btn-sm py-0"
          onClick={() => handleCopy(d.tk ?? "")}
        >
          {(d.tk ?? "").slice(0, 10) + "..."}
        </button>
      </td>
      <td>{d.fecha_registro ?? ""}</td>
      <td>{d.fecha_actualizacion ?? ""}</td>
      <td>{d.estado ?? ""}</td>
    </tr>
  ));

  return (
    <div className="adminAllLinks page">
      <div className="main-content-container container-fluid px-4">
        <div className="page-header row no-gutters py-4">
          <div className="col-12 col-sm-4 text-start text-sm-left mb-0">
            <span className="text-uppercase page-subtitle">Administración</span>
            <h3 className="page-title">Usuarios Cliente</h3>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="mb-4">
              <div className="card-header border-bottom pb-3">
                <div className="row justify-content-between">
                  <div className="col-6">
                    <h6 className="m-0">Usuarios registrados</h6>
                  </div>
                  <div className="col-6 text-end"></div>
                </div>
              </div>
              <div className="card-body p-0 pb-3 text-center">
                <table className="table mb-0 table-hover">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        #
                      </th>
                      <th scope="col" className="border-0">
                        Mail
                      </th>
                      <th scope="col" className="border-0">
                        TK
                      </th>
                      <th scope="col" className="border-0">
                        registro
                      </th>
                      <th scope="col" className="border-0">
                        modificación
                      </th>
                      <th scope="col" className="border-0">
                        Estado
                      </th>
                    </tr>
                  </thead>
                  <tbody>{listItems}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminUsersClient;
