import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { toastError } from "../../../alerts/toasAlerts";
import { edithEnlace, linksEnlace, linksEnlaceConAcceso, nuevoEnlace, nuevoEnlaceConAcceso } from "../../../http/requestLink";
import { sistemasLista } from "../../../http/requestSistema";
import { AiFillEdit } from "react-icons/ai"
import { listAccesToken } from "../../../http/requestAccesToken";
import { useUsuario } from "../../../context/UsuarioContext";

const AdminAllAccesosEnlace = () => {

  const { setSpinnerLoad } = useUsuario();

  const [modalNew, setModalNew] = useState(false);
  const [modalEdith, setModalEdith] = useState(false);
  const [dataModalEdith, setDataModalEdith] = useState(false);

  const [data, setData] = useState([]);
  const [enlaces, setEnlaces] = useState([]);
  const [accesos, setAccesos] = useState([]);

  useEffect(() => {

    const loadLinksConAcceso = () => {
      
      setSpinnerLoad(true);
      linksEnlaceConAcceso().then(
        data => {
          console.log(data);
          if (data != null) { setData(data); }
          setSpinnerLoad(false);
        }
      )
    };

    const loadLinks = () => {
      linksEnlace().then(
        data => {
          console.log(data);
          if (data != null) { setEnlaces(data); }
        }
      )
    };

    const loadAccesos = () => {
      listAccesToken().then(
        data => {
          console.log(data);
          if (data != null) { setAccesos(data); }
        }
      )
    };

    loadLinksConAcceso();
    loadLinks();
    loadAccesos();
  }, []);


  const nuevo = (d) => {
    console.log(d);
    nuevoEnlaceConAcceso(d).then(
      data => {
        console.log(data);
        if (data != null) { setData(data); }
      }
    )
  }




  const listItems = data.map((d, index) =>
    <tr key={index.toString()}>
      <td>{index + 1}</td>
      <td>{d.link.sistema.nombre ?? ""}</td>
      <td className="help-cursor" data-bs-toggle="tooltip" data-bs-placement="top" title={d.link.descripcion ?? ""}>{d.link.enlace ?? ""}</td>
      <td className="text-secondary fst-italic" style={{"fontSize":"10px"}}>{d.codigo ?? ""}</td>
      <td>{d.link.metodo ?? ""}</td>
      <td className="help-cursor" data-bs-toggle="tooltip" data-bs-placement="top" title={d.tokenacceso.descripcion ?? ""}>{d.tokenacceso.usuariorol.usuario.nombre ?? ""}</td>
      <td>
        <button type="button" className="btn btn-light btn-sm"><AiFillEdit className="text-info"/></button>
      </td>
    </tr>);


  return (
    <div className="adminAllLinks page">
      <div className="main-content-container container-fluid px-4">
        <div className="page-header row no-gutters py-4">
          <div className="col-12 col-sm-4 text-start text-sm-left mb-0">
            <span className="text-uppercase page-subtitle">Administración</span>
            <h3 className="page-title">Accesos a Enlaces</h3>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="mb-4">
              <div className="card-header border-bottom pb-3">
                <div className="row justify-content-between">
                  <div className="col-6">
                    <h6 className="m-0">Accesos a Enlaces disponibles</h6>
                  </div>
                  <div className="col-6 text-end">
                    <Button variant="link" onClick={(e) => setModalNew(true)}>Agregar Nuevo</Button>
                  </div>
                </div>
              </div>
              <div className="card-body p-0 pb-3 text-center">
                <table className="table mb-0 align-middle">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">#</th>
                      <th scope="col" className="border-0">Sistema</th>
                      <th scope="col" className="border-0">Enlace</th>
                      <th scope="col" className="border-0">Código Enlace</th>
                      <th scope="col" className="border-0">Metodo</th>
                      <th scope="col" className="border-0">Usuario Cliente</th>
                      <th scope="col" className="border-0"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {listItems}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalNewLinkEnlace
        show={modalNew}
        onHide={() => setModalNew(false)}
        onNuevo={nuevo}
        accesos={accesos}
        enlaces={enlaces}
      />

    </div>
  );
}


function ModalNewLinkEnlace(props) {


  const [enlace, setEnlace] = useState("");
  const [acceso, setAcceso] = useState("");



  function validacion() {

    if (enlace === "" || enlace === null) {
      toastError("Es necesario seleccionar un enlace en el formulario");
      return false;
    }   
    if (acceso === "" || acceso === null) {
      toastError("Es necesario seleccionar un acceso en el formulario");
      return false;
    }


    return true;
  }

  function limpiarYsalir() {

    setEnlace("");
    setAcceso("");
    props.onHide();
  }

  function addNew() {
    /*console.log({
        descrip: descrip,
    });
    */
    if(validacion()){
        props.onNuevo({
            enlace:enlace,
            acceso:acceso
        });
        limpiarYsalir();
    }
  }



  return (
    <div className="modal_upInfo">
      <Modal show={props.show} onHide={props.onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}>

        <Form>
          <Modal.Header>
            <Modal.Title className="col-12">
              <p className="text-center">Agregar y Habilitar Nuevo Acceso a Enlace</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label><strong>Enlace</strong></Form.Label>
              <Form.Select aria-label="Default select example">
                <option onClick={(e) => setEnlace("")}>Seleccione una Opción</option>
                {props.enlaces.map((enlace, index) => {
                  return <option onClick={(e) => setEnlace(enlace.idlink)}
                    key={index} value={enlace.idlink}>{enlace.sistema.nombre} {enlace.metodo}  {enlace.enlace}</option>
                })}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label><strong>Token Usuario</strong></Form.Label>
              <Form.Select aria-label="Default select example">
                <option onClick={(e) => setAcceso("")}>Seleccione una Opción</option>
                {props.accesos.map((acceso, index) => {
                  return <option onClick={(e) => setAcceso(acceso.codigo)}
                    key={index} value={acceso.codigo}>{acceso.rol.nombre_usuario} - {acceso.descripcion}</option>
                })}
              </Form.Select>
            </Form.Group>

          </Modal.Body>
          <Modal.Footer className='p-0'>
            <div className='row col-12 m-0'>
              <div onClick={limpiarYsalir} className='col-6 btn-ios rounded-bottom'>Cancelar</div>
              <span onClick={addNew} className='col-6 border-start btn-ios rounded-bottom'>Guardar</span>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}


export default AdminAllAccesosEnlace;