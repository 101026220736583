import axios from "axios";
import { toastError, toastSuccess } from "../alerts/toasAlerts";
import { loginAccess_url } from "../config/urls/urlLogin";
import { verificarCode } from "../functions/verificResponse"

export async function loginAcc(data) {
  return fetch(loginAccess_url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "/*",
    },
    body: JSON.stringify(data)
  })
    .then(data => {
      //console.log(data);
      if (data.ok && data.status === 200) {
        return data.json();
      } else {
        console.log("Conexion fallida");
        toastError("Ocurrió un error, intenta nuevamente");
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      toastError("Ocurrió un error al conectar con el servidor");
    })

  }