
import { Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Sidebar from "./layers/sidebar/Sidebar";
import Navbar from "./layers/navbar/Navbar";
import "./style/dark.css";
import "./app.css";
import AdminAllLinks from "./pages/admins/adminAllLinks/AdminAllLinks";
import { useUsuario, UsuarioProvider } from "./context/UsuarioContext";
import Login from "./pages/login/login";
import { Toaster } from "react-hot-toast";
import AdminAllSistemas from "./pages/admins/adminAllSistemas/AdminAllsistemas";
import AdminAllUsuarios from "./pages/admins/adminAllUsuarios/AdminAllUsuarios";
import AdminAllRolesAsignados from "./pages/admins/adminAllRolesAsignados/AdminAllRolesAsignados";
import AdminAllTokensAcceso from "./pages/admins/adminAllTokenAcceso/AdminAllTokensAcceso";
import AdminAllAccesosEnlace from "./pages/admins/adminAllAccesoEnlaces/AdminAllAccesosEnlace";
import ModalLoad from "./components/modals/ModalLoad";
import { ProtectedRoute } from "./components/protectedRoute/ProtectedRoute";
import AdminUsersClient from "./pages/admins/usuariosCliente/AdminUsersClient";
import AdminCalendarFarmacias from "./pages/admins/calendarFarmacias/AdminCalendarFarmacias";
import AdminCalendarGlp from "./pages/admins/calendarGlp/AdminCalendarGlp";
import AdminSendNotificationApp from "./pages/admins/usuariosCliente/AdminSendNotificationApp";
import AdminAllBuss from "./pages/admins/adminAllBussiness/AdminAllBuss";
import AdminAllCategories from "./pages/admins/adminAllCategorias/AdminAllCategories";


export default () => <UsuarioProvider>
  <App></App>
  <Toaster
    position="bottom-center"
    reverseOrder={true}
  />
</UsuarioProvider>

function App() {


  /*
    return (
  <div className={darkMode ? "app dark" : "app"}>
        <BrowserRouter>
          <Routes>
            <Route path="/">
              <Route index element={<Home />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    );*/

  const { token, darkMode, infoBasicData } = useUsuario();

  if (!token) {

    return (
      <div>
        <Routes basename={'/'}>
          <Route exact path="/*" element={<Login />} />
        </Routes>
      </div>
    )

  }


  return (
    <div className={darkMode ? "app dark" : "app"}>
      <Sidebar />
      <div className="container-app">
        <Navbar />
        {/*<Topbar onSide={() => setShowSide(!showSide)} />*/}
        <div className="pages pb-5">
          <Routes>
            <Route exact path="/*" element={<Home />} />
            <Route path="/inicio" element={<Home />} />
            <Route element={<ProtectedRoute isAllowed={/*infoBasicData?.nivel==0*/true} redirectTo="/inicio" />}>
              <Route path="/calendarFarmacias" element={<AdminCalendarFarmacias />} />
              <Route path="/calendarGlp" element={<AdminCalendarGlp />} />
              <Route path="/usuariosCliente" element={<AdminUsersClient />} />
              <Route path="/notificationFCM" element={<AdminSendNotificationApp />} />
              <Route path="/negocios" element={<AdminAllBuss />} />
              <Route path="/categorias" element={<AdminAllCategories />} />
              
              
            </Route>
          </Routes>
        </div>
      </div>
      <ModalLoad />
    </div>
  );
}