import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { toastError } from "../../../alerts/toasAlerts";
import { edithEnlace, linksEnlace, nuevoEnlace } from "../../../http/requestLink";
import { sistemasLista } from "../../../http/requestSistema";
import { AiFillEdit } from "react-icons/ai"

const AdminAllLinks = () => {


  const [modalNew, setModalNew] = useState(false);
  const [modalEdith, setModalEdith] = useState(false);
  const [dataModalEdith, setDataModalEdith] = useState(false);

  const [data, setData] = useState([]);
  const [sistemas, setSistemas] = useState([]);

  useEffect(() => {

    const loadLinks = () => {
      linksEnlace().then(
        data => {
          console.log(data);
          if (data != null) { setData(data); }
        }
      )
    };

    const loadSistemas = () => {
      sistemasLista().then(
        data => {
          console.log(data);
          if (data != null) { setSistemas(data); }
        }
      )
    };

    loadLinks();
    loadSistemas();
  }, []);


  const nuevo = (d) => {
    console.log(d);
    nuevoEnlace(d).then(
      data => {
        console.log(data);
        if (data != null) { setData(data); }
      }
    )
  }


  const openModalEdith = (d) => {
    console.log("Abrimos el modal");
    setDataModalEdith(d);
    setModalEdith(true);
  }

  const edith = (d) => {
    console.log(d);
       edithEnlace(d).then(
        data => {
          console.log(data);
          if (data != null) { setData(data); }
        }
      )
  }

  const listItems = data.map((d, index) =>
    <tr key={index.toString()}>
      <td>{index + 1}</td>
      <td>{d.enlace ?? ""}</td>
      <td>{d.metodo ?? ""}</td>
      <td>{d.sistema.nombre ?? ""}</td>
      <td>{d.descripcion ?? ""}</td>
      <td>
      <button type="button" className="btn btn-light btn-sm" onClick={(e)=>{openModalEdith(d)}}><AiFillEdit className="text-info"/></button>
        </td>
    </tr>);


  return (
    <div className="adminAllLinks page">
      <div className="main-content-container container-fluid px-4">
        <div className="page-header row no-gutters py-4">
          <div className="col-12 col-sm-4 text-start text-sm-left mb-0">
            <span className="text-uppercase page-subtitle">Administración</span>
            <h3 className="page-title">Enlaces</h3>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="mb-4">
              <div className="card-header border-bottom pb-3">
                <div className="row justify-content-between">
                  <div className="col-6">
                    <h6 className="m-0">Enlaces disponibles</h6>
                  </div>
                  <div className="col-6 text-end">
                    <Button variant="link" onClick={(e) => setModalNew(true)}>Agregar Nuevo</Button>
                  </div>
                </div>
              </div>
              <div className="card-body p-0 pb-3 text-center">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">#</th>
                      <th scope="col" className="border-0">Enlace</th>
                      <th scope="col" className="border-0">Metodo</th>
                      <th scope="col" className="border-0">Sistema</th>
                      <th scope="col" className="border-0">Descripción</th>
                      <th scope="col" className="border-0"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {listItems}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalNewLinkEnlace
        show={modalNew}
        onHide={() => setModalNew(false)}
        onNuevo={nuevo}
        sistemas={sistemas}
      />

<ModalEdithLinkEnlace
        show={modalEdith}
        onHide={() => setModalEdith(false)}
        onNuevo={edith}
        sistemas={sistemas}
        data={dataModalEdith}
      />
    </div>
  );
}


function ModalNewLinkEnlace(props) {


  const [sistema, setSistema] = useState("");
  const [enlace, setEnlace] = useState("");
  const [metodo, setMetodo] = useState("");
  const [descrip, setDescrip] = useState("");



  function validacion() {

    if (sistema === "" || sistema === null) {
      toastError("Es necesario seleccionar un sistema en el formulario");
      return false;
    }

    return true;
  }

  function limpiarYsalir() {

    setSistema("");
    props.onHide();
  }

  function addNew() {
    /*console.log({
        descrip: descrip,
    });
    */
    if(validacion()){
        props.onNuevo({
            desc: descrip,
            metodo:metodo,
            idSistema:sistema,
            enlace:enlace
        });
        limpiarYsalir();
    }
  }



  return (
    <div className="modal_upInfo">
      <Modal show={props.show} onHide={props.onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}>

        <Form>
          <Modal.Header>
            <Modal.Title className="col-12">
              <p className="text-center">Agregar y Habilitar Nuevo Enlace</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label><strong>Sistema Informático</strong></Form.Label>
              <Form.Select aria-label="Default select example">
                <option onClick={(e) => setSistema("")}>Seleccione una Opción</option>
                {props.sistemas.map((sistema, index) => {
                  return <option onClick={(e) => setSistema(sistema.idsistema)}
                    key={index} value={sistema.idsistema}>{sistema.nombre}</option>
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label><strong>Enlace</strong></Form.Label>
              <Form.Control
                type="text"
                placeholder="Ej: /v1/sistema/AdminSistemaAllList"
                onChange={(e) => setEnlace(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label><strong>Metodo</strong></Form.Label>
              <Form.Select aria-label="Default select example">
                <option onClick={(e) => setMetodo("")}>Seleccione una Opción</option>
                <option onClick={(e) => setMetodo("GET")}>GET</option>
                <option onClick={(e) => setMetodo("POST")}>POST</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label><strong>Descripción</strong></Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                onChange={(e) => setDescrip(e.target.value)}
              />
            </Form.Group>

          </Modal.Body>

          <Modal.Footer className='p-0'>
            <div className='row col-12 m-0'>
              <div onClick={limpiarYsalir} className='col-6 btn-ios rounded-bottom'>Cancelar</div>
              <span onClick={addNew} className='col-6 border-start btn-ios rounded-bottom'>Guardar</span>
            </div>
          </Modal.Footer>

        </Form>
      </Modal>
    </div>
  );
}


function ModalEdithLinkEnlace(props) {


  const [id, setId] = useState("");
  const [sistema, setSistema] = useState("");
  const [enlace, setEnlace] = useState("");
  const [metodo, setMetodo] = useState("");
  const [descrip, setDescrip] = useState("");

  useEffect(() => {
    setId(props.data.idlink);
    setSistema(props.data.sistema?.idsistema);
    setEnlace(props.data.enlace);
    setMetodo(props.data.metodo);
    setDescrip(props.data.descripcion);
  }, [props]);


  function validacion() {

    if (id === "" || id === null) {
      toastError("Ocurrió un error");
      return false;
    }
    if (sistema === "" || sistema === null) {
      toastError("Es necesario seleccionar un sistema en el formulario");
      return false;
    }
    if (enlace === "" || enlace === null) {
      toastError("Es un enlace en el formulario");
      return false;
    }
    if (metodo === "" || metodo === null) {
      toastError("Es necesario seleccionar un metodo en el formulario");
      return false;
    }
    if (descrip === "" || descrip === null) {
      toastError("Es necesario una descripción formulario");
      return false;
    }

    return true;
  }

  function limpiarYsalir() {

    setId("");
    setSistema("");
    setEnlace("");
    setMetodo("");
    setDescrip("");
    props.onHide();
  }

  function addNew() {
    /*console.log({
        descrip: descrip,
    });
    */
    if(validacion()){
        props.onNuevo({
            idLink: id,
            desc: descrip,
            metodo:metodo,
            idSistema:sistema,
            enlace:enlace
        });
        limpiarYsalir();
    }
  }



  return (
    <div className="modal_upInfo">
      <Modal show={props.show} onHide={props.onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}>

        <Form>
          <Modal.Header>
            <Modal.Title className="col-12">
              <p className="text-center">Editar Enlace</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label><strong>Sistema Informático</strong></Form.Label>
              <Form.Select aria-label="Default select example">
              <option onClick={(e) => setMetodo(props.data.sistema?.idsistema)}>{props.data.sistema?.nombre}</option>
                {props.sistemas.map((sistema, index) => {
                  return <option onClick={(e) => setSistema(sistema.idsistema)}
                    key={index} value={sistema.idsistema}>{sistema.nombre}</option>
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label><strong>enlace</strong></Form.Label>
              <Form.Control
                type="text"
                placeholder="Ej: /v1/sistema/AdminSistemaAllList"
                defaultValue={props.data.enlace}
                onChange={(e) => setEnlace(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label><strong>Metodo</strong></Form.Label>
              <Form.Select aria-label="Default select example">
                <option onClick={(e) => setMetodo(props.data.metodo)}>{props.data.metodo}</option>
                <option onClick={(e) => setMetodo("GET")}>GET</option>
                <option onClick={(e) => setMetodo("POST")}>POST</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label><strong>Descripción</strong></Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                defaultValue={props.data.descripcion}
                onChange={(e) => setDescrip(e.target.value)}
              />
            </Form.Group>

          </Modal.Body>

          <Modal.Footer className='p-0'>
            <div className='row col-12 m-0'>
              <div onClick={limpiarYsalir} className='col-6 btn-ios rounded-bottom'>Cancelar</div>
              <span onClick={addNew} className='col-6 border-start btn-ios rounded-bottom'>Guardar</span>
            </div>
          </Modal.Footer>

        </Form>
      </Modal>
    </div>
  );
}

export default AdminAllLinks;