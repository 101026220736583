import React from 'react';
import { useState} from 'react';
import './login.css';
import * as FaIcons from 'react-icons/fa';
import { useUsuario } from '../../context/UsuarioContext';
import { Link } from 'react-router-dom';
import { loginAcc } from '../../http/requestLogin';
import { toastError } from '../../alerts/toasAlerts';
import toast, { Toaster } from 'react-hot-toast';

export default function Login() {
 

    const { setToken, setSpinnerLoad } = useUsuario();

    const [password, setPassword] = useState();
    const [username, setUserName] = useState();

    const [loginError, setLoginError] = useState("");

    const [passwordError, setpasswordError] = useState("");
    const [emailError, setemailError] = useState("");
  
    const handleValidation = (event) => {
      let formIsValid = true;
  
      if (!username.match(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)) {
        formIsValid = false;
        setemailError("Email No Valido");
        return false;
      } else {
        setemailError("");
        formIsValid = true;
      }
  
      if (!password.match(/^[a-zA-Z]{8,22}$/)) {
        formIsValid = false;
        setpasswordError(
          "Only Letters and length must best min 8 Chracters and Max 22 Chracters"
        );
        return false;
      } else {
        setpasswordError("");
        formIsValid = true;
      }
  
      return formIsValid;
    };
  
    const loginSubmit = (e) => {
      e.preventDefault();
      handleValidation();
    };
  
    function handleClick(e) {
      e.preventDefault();
      setToken();
    }


    const handleSubmit = async e => {
      e.preventDefault();

      setSpinnerLoad(true);
      
     let data = await loginAcc({
        username,
        password
      });      
      if(data!=null ){
        if(data.status===200 && data.ok){
          console.log(data);
          setToken(data.data.token);
        }else{
          toastError(data.mensaje);
          setLoginError(data.mensaje);
        }
      }
      setSpinnerLoad(false);
    }

    return (
        <div className='login d-flex align-items-center'>
          
            <div className="container">
                  <div className="d-flex justify-content-center">
                    <div className="login-box col-md-4 bg-white m-3 shadow mb-5 bg-body rounded">
                        <div className="card-header text-center bg-white p-3">
                          <b className='h1'>Mi Cobija</b><span></span>
                          </div>
                          <div className="card-body text-center px-4 pb-4">
                            <p className="login-box-msg text-muted mb-3">Sistema de administrativo</p>
                            <form onSubmit={handleSubmit}>
                            <div className="input-group mb-3">
                                <input onChange={e => setUserName(e.target.value)} type="text" className="form-control" placeholder="Usuario" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                                <span className="input-group-text" id="basic-addon2"><FaIcons.FaUserAlt /></span>
                            </div>
                            
                            <div className="input-group mb-2">
                                <input onChange={e => setPassword(e.target.value)} name="password" placeholder="Password" type="password" id="password" className="form-control"/>
                                <span className="input-group-text" id="basic-addon2"><FaIcons.FaLock /></span>
                            </div>

                            <div className='mb-3 text-danger font12'>
                                <strong>{loginError}</strong>
                            </div>

                            <div className="row">
                              <div className="col-12 align-self-end">
                                <button type="submit" className="btn btn-primary btn-block" >Acceder</button>
                              </div>
                            </div>
                            
                            </form>
                          </div>
                    </div>
                  </div>
            </div>
        </div>
    );
}

