import { useEffect, useState } from "react";
import { httpGet, httpPost } from "../../../http/request";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { toastError } from "../../../alerts/toasAlerts";

const AdminAllCategories = () => {
  const [cats, setCats] = useState([]);
  const [modalNewCat, setModalNewCat] = useState(false);
  const [modalEdit, setModalEdith] = useState({
    show: false,
    code: 0,
    data: {},
  });

  const loadCats = () => {
    httpGet("v1/adm/categoria/listarTodas").then((data) => {
      console.log(data);
      if (data != null) {
        setCats(data);
      }
    });
  };

  useEffect(() => {
    loadCats();
  }, []);

  const handleCopy = async (textToCopy) => {};

  const listItems = cats.map((d, index) => (
    <tr key={index.toString()}>
      <td>{index + 1}</td>
      <td>{d.nombre ?? ""}</td>
      <td>{d.codigo ?? ""}</td>
      <td>
        <Button
          size="sm"
          className="mx-1"
          variant="outline-primary"
          onClick={() => setModalEdith({ show: true, code: d.code, data: d })}
        >
          Detalles
        </Button>
      </td>
    </tr>
  ));

  return (
    <div className="adminAllLinks page">
      <div className="main-content-container container-fluid px-4">
        <div className="page-header row no-gutters py-4">
          <div className="col-12 col-sm-4 text-start text-sm-left mb-0">
            <span className="text-uppercase page-subtitle">Administración</span>
            <h3 className="page-title">Categorias</h3>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="mb-4">
              <div className="card-header border-bottom pb-3">
                <div className="row justify-content-between">
                  <div className="col-6">
                    <h6 className="m-0">Lista de Categorias registrados</h6>
                  </div>

                  <div className="col-6 text-end">
                    <Button
                      size="sm"
                      className="mx-1"
                      variant="secondary"
                      onClick={() => loadCats()}
                    >
                      Actualizar Lista
                    </Button>
                    <Button
                      size="sm"
                      className="mx-1"
                      onClick={() => setModalNewCat(true)}
                    >
                      + Agregar Nuevo
                    </Button>
                  </div>
                </div>
              </div>
              <div className="card-body p-0 pb-3 text-center">
                <table className="table mb-0 table-hover">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        #
                      </th>
                      <th scope="col" className="border-0">
                        Nombre
                      </th>
                      <th scope="col" className="border-0">
                        Código
                      </th>
                      <th scope="col" className="border-0"></th>
                    </tr>
                  </thead>
                  <tbody>{listItems}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalNewCategoria
        show={modalNewCat}
        onHide={() => setModalNewCat(false)}
        onSuccess={() => loadCats()}
      />

      <ModalEditCat
        show={modalEdit.show}
        onHide={() => setModalEdith({ show: false, code: 0 })}
        onSuccess={() => loadCats()}
        data={modalEdit.data ?? {}}
      />
    </div>
  );
};

export default AdminAllCategories;

function ModalNewCategoria(props) {
  const [nombre, setNombre] = useState("");
  const [codigo, setCodigo] = useState("");

  useEffect(() => {
    /*ListaFarmacias().then((d) => {
      if (d) {
        let l = [];
        d.forEach(element => {
          l.push({ value: element["id"], label: element["nombre"] });
        });
        setOptionsPharmas(l);
      }
    });*/
  }, []);

  function validacion() {
    if (nombre === "" || nombre === null) {
      toastError(
        "Es necesario ingresar un nombre de categoría en el primer campo"
      );
      return false;
    }

    if (codigo === "" || codigo === null) {
      toastError("Es necesario ingresar un código en el primer campo");
      return false;
    }

    return true;
  }

  function limpiarYsalir() {
    setNombre("");
    setCodigo("");
    props.onHide();
  }

  function addNew(data) {
    if (validacion()) {
      httpPost("v1/adm/categoria/createCategoria", data).then((d) => {
        if (d) {
          props.onSuccess();
          limpiarYsalir();
        }
      });
    }
  }

  return (
    <div className="modal_upInfo">
      <Modal
        show={props.show}
        onHide={props.onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}
      >
        <Form>
          <Modal.Header>
            <Modal.Title className="col-12">
              <p className="text-center">Agregar Nueva Categoría</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label>
                <strong>Nombre de Categoría</strong>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Hospedajes"
                autoFocus={true}
                onChange={(e) => setNombre(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label>
                <strong>Código (Debe ser único)</strong>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="HOSP-1"
                onChange={(e) => setCodigo(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer className="p-0">
            <div className="row col-12 m-0">
              <button
                type="button"
                onClick={limpiarYsalir}
                className="col-6 btn-ios rounded-bottom"
              >
                Cancelar
              </button>
              <button
                type="button"
                onClick={() => addNew({ nombre, codigo })}
                className="col-6 border-start btn-ios rounded-bottom"
              >
                Guardar
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}

function ModalEditCat(props) {
  const [modalEdit, setModalEdith] = useState({
    show: false,
    code: 0,
    data: {},
  });

  const [data, setData] = useState({});
  const [dataSubs, setDataSubs] = useState([]);

  function cargarDatos(code) {
    httpPost("v1/adm/categoria/detalleCode", { code }).then((d) => {
      if (d) {
        setData(d);
      }
    });
  }

  function loadSubCats() {
    httpPost("v1/adm/subcategoria/listarPorCategoria", {
      code: props.data.code,
    }).then((d) => {
      if (d) {
        setDataSubs(d);
      }
    });
  }

  useEffect(() => {
    if (props.show && props.data.code > 0) {
      cargarDatos(props.data.code);
      loadSubCats();
    }
  }, [props]);

  function limpiarYsalir() {
    setData({});
    props.onHide();
  }

  function guardar(data) {
    if (!data.nombre && !data.codigo && !data.direccion && !data.estado) {
      toastError("Debe ingresar todos los datos obligatorios");
      return;
    }
    httpPost("v1/adm/categoria/updateCat", data).then((d) => {
      if (d) {
        props.onSuccess();
        limpiarYsalir();
      }
    });
  }

  return (
    <div className="modal_upInfo">
      <Modal
        show={props.show}
        onHide={props.onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Form>
          <Modal.Header>
            <Modal.Title className="col-12">
              <p className="text-center">Editar {props.data.nombre ?? ""}</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={12} md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput3"
                >
                  <Form.Label>
                    <strong>Nombre de la Categoría</strong>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={data.nombre}
                    onChange={(e) =>
                      setData({ ...data, nombre: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={3}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput3"
                >
                  <Form.Label>
                    <strong>Código</strong>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={data.codigo}
                    onChange={(e) =>
                      setData({ ...data, codigo: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>

              <Col sm={12} md={3}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput3"
                >
                  <Form.Label>
                    <strong>Estado</strong>
                  </Form.Label>
                  <select
                    className="form-select"
                    aria-label=""
                    value={data.estado}
                    onChange={(e) =>
                      setData({ ...data, estado: e.target.value })
                    }
                  >
                    <option value="">Selecciona una Opción</option>
                    <option value="A">Activo</option>
                    <option value="B">No Activo</option>
                  </select>
                </Form.Group>
              </Col>
              <Col sm={12} md={12}>
                <hr />
                <div className="d-flex  mt-4">
                  <p className="h6">SubCategorias</p>
                  <div className="ms-auto">
                    <button
                      className="btn btn-light btn-sm"
                      type="button"
                      onClick={() =>
                        setModalEdith({
                          show: true,
                          code: 0,
                          data: { codeCat: data.code },
                        })
                      }
                    >
                      Agregar +
                    </button>
                  </div>
                </div>
                <Row>
                  {dataSubs.map((d, i) => (
                    <Col key={i} sm={3}>
                      <div className="card">
                        <img
                          className="card-img-top img-fluid border-bottom"
                          src={d.icono}
                          alt="imagenicono"
                          style={{ objectFit: "none" }}
                        />
                        <div className="card-body">
                          <h4 className="card-title">{d.nombre}</h4>
                          <button
                            className="btn btn-primary btn-sm"
                            type="button"
                            onClick={() =>
                              setModalEdith({
                                show: true,
                                code: 0,
                                data: d,
                              })
                            }
                          >
                            Editar
                          </button>
                        </div>
                      </div>
                    </Col>
                  ))}
                  {/*<Col sm={3}>
                    <div className="card">
                      <img
                        className="card-img-top img-fluid"
                        src="/assets/images/small/img-1.jpg"
                        alt="Card image cap"
                      />
                      <div className="card-body">
                        <h4 className="card-title">Card title</h4>
                        <p className="card-text">
                          Some quick example text to build on the card title and
                          make up the bulk of the card's content.
                        </p>
                        <button className="btn btn-primary btn-sm">Ok</button>
                      </div>
                    </div>
                  </Col>*/}
                </Row>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer className="p-0">
            <div className="row col-12 m-0">
              <button
                type="button"
                onClick={limpiarYsalir}
                className="col-6 btn-ios rounded-bottom"
              >
                Cancelar
              </button>
              <button
                type="button"
                onClick={() => guardar(data)}
                className="col-6 border-start btn-ios rounded-bottom"
              >
                Guardar
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

      <ModalEditSubCat
        show={modalEdit.show}
        onHide={() => setModalEdith({ show: false, code: 0 })}
        onSuccess={() => loadSubCats()}
        data={modalEdit.data ?? {}}
      />
    </div>
  );
}

function ModalEditSubCat(props) {
  const [data, setData] = useState({});

  function cargarDatos(code) {
    httpPost("v1/adm/subcategoria/detalleCode", { code }).then((d) => {
      if (d) {
        setData(d);
      }
    });
  }

  useEffect(() => {
    if (props.show && props.data.code > 0) {
      cargarDatos(props.data.code);
    }
  }, [props]);

  function limpiarYsalir() {
    setData({});
    props.onHide();
  }

  function guardar(data) {
    console.log("////////////***////////////");
    console.log(props.data);
    if (
      !props.data.codeCat &&
      !data.nombre &&
      !data.codigo &&
      !data.icono &&
      !data.estado
    ) {
      toastError("Debe ingresar todos los datos obligatorios");
      return;
    }
    if (props.data.code > 0) {
      httpPost("v1/adm/subcategoria/updateSubCat", data).then((d) => {
        if (d) {
          props.onSuccess();
          limpiarYsalir();
        }
      });
    } else {
      data.codeCat = props.data.codeCat;
      console.log("//////////////////////");
      //console.log(data);
      httpPost("v1/adm/subcategoria/createSubCategoria", data).then((d) => {
        if (d) {
          props.onSuccess();
          limpiarYsalir();
        }
      });
    }
  }

  return (
    <div className="modal_upInfo">
      <Modal
        show={props.show}
        onHide={props.onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Form>
          <Modal.Header>
            <Modal.Title className="col-12">
              {props.code ? (
                <p className="text-center">Editar {props.data.nombre ?? ""}</p>
              ) : (
                <p className="text-center">Nueva Subcategoria</p>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={12} md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput3"
                >
                  <Form.Label>
                    <strong>Nombre de Subcategoría</strong>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={data.nombre}
                    onChange={(e) =>
                      setData({ ...data, nombre: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={3}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput3"
                >
                  <Form.Label>
                    <strong>Código</strong>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={data.codigo}
                    onChange={(e) =>
                      setData({ ...data, codigo: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>

              <Col sm={12} md={3}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput3"
                >
                  <Form.Label>
                    <strong>Estado</strong>
                  </Form.Label>
                  <select
                    class="form-select"
                    aria-label=""
                    value={data.estado}
                    onChange={(e) =>
                      setData({ ...data, estado: e.target.value })
                    }
                  >
                    <option value="">Selecciona una Opción</option>
                    <option value="A">Activo</option>
                    <option value="B">No Activo</option>
                  </select>
                </Form.Group>
              </Col>
              <Col sm={12} md={12}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput3"
                >
                  <Form.Label>
                    <strong>Icono base64</strong>
                  </Form.Label>

                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="data:image/png;base64,iVBOR..."
                    value={data.icono}
                    onChange={(e) =>
                      setData({ ...data, icono: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer className="p-0">
            <div className="row col-12 m-0">
              <button
                type="button"
                onClick={limpiarYsalir}
                className="col-6 btn-ios rounded-bottom"
              >
                Cancelar
              </button>
              <button
                type="button"
                onClick={() => guardar(data)}
                className="col-6 border-start btn-ios rounded-bottom"
              >
                Guardar
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}
