import "./navbar.css";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import { useUsuario } from "../../context/UsuarioContext";

const Navbar = () => {

  const { darkMode,setDarkMode,infoBasicData } = useUsuario();

  /*const modoDark=()=>{
    return( <DarkModeOutlinedIcon
      className="icon"
      onClick={() => setDarkMode(true)}
    />);
  }*/

  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="search">
          {/*
          <input type="text" placeholder="Search..." />
          <SearchOutlinedIcon />
          */}
        </div>
        <div className="items">
          {/*
          <div className="item">
            <LanguageOutlinedIcon className="icon" />
            English
          </div>
          */}

          <div className="item pointer">
            {darkMode?
            <><LightModeOutlinedIcon
            className="icon"
            onClick={() => setDarkMode(false)}
          /></>
          :
          <><DarkModeOutlinedIcon
          className="icon"
          onClick={() => setDarkMode(true)}
        /></>}
            
          </div>
          {/*
          <div className="item">
            <FullscreenExitOutlinedIcon className="icon" />
          </div>
          <div className="item">
            <NotificationsNoneOutlinedIcon className="icon" />
            <div className="counter">1</div>
          </div>
          <div className="item">
            <ChatBubbleOutlineOutlinedIcon className="icon" />
            <div className="counter">2</div>
          </div>
          <div className="item">
            <ListOutlinedIcon className="icon" />
          </div>
        */}

          <div className="item">
            <span>{infoBasicData?.nombre}</span>
          </div>
          <div className="item">
            <img
              src="https://images.pexels.com/photos/941693/pexels-photo-941693.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
              alt=""
              className="avatar"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
