import axios from "axios";
import { toastError } from "../alerts/toasAlerts";
import { infoUsuario_url, listUsersAllAdmin_url, nuevoUsuario_url } from "../config/urls/urlUsuarios";
import { infoCodTK } from "../functions/infoTk";
import { verificarCode } from "../functions/verificResponse"
import { new_notificacion_personal_url, new_notificacion_url } from "../config/urls/urlFcm";
 
export async function nuevaNotificacionFcm(titulo,texto,imagen) {

    return axios.post(new_notificacion_url,{
      titulo,texto,imagen
    }, {
      headers: {
        'Authorization': `Bearer ${infoCodTK()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      //console.log(data.data);
      if (data.data.ok && data.status === 200) {
        return data.data.data;
      } else {
        toastError(data.data.mensaje);
        verificarCode(data.data.status);
        console.log("Conexion fallida");
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      //mostrarAlerta("Ocurrió un error al conectar con el servidor");
    });
  
  }

export async function nuevaNotificacionPersonalFcm(titulo,texto,imagen,token) {

    return axios.post(new_notificacion_personal_url,{
      titulo,texto,imagen,token
    }, {
      headers: {
        'Authorization': `Bearer ${infoCodTK()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      //console.log(data.data);
      if (data.data.ok && data.status === 200) {
        return data.data.data;
      } else {
        toastError(data.data.mensaje);
        verificarCode(data.data.status);
        console.log("Conexion fallida");
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      //mostrarAlerta("Ocurrió un error al conectar con el servidor");
    });
  
  }


