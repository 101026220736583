
export function verificarCode(code) {
    switch (code) {
      case 403:
        localStorage.clear();
        window.location.reload(false);
        break;
      case 200:
        break;
      default:
        console.log(`Sorry, we are out of ${code}.`);
    }
  }
  