import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { nuevaNotificacionFcm, nuevaNotificacionPersonalFcm } from "../../../http/requestFcm";
import { toastError } from "../../../alerts/toasAlerts";

const AdminSendNotificationApp = () => {


  const [titulo, setTitulo] = useState("");
  const [texto, setTexto] = useState("");
  const [imagen, setImagen] = useState("");
  const [token, setToken] = useState("");

  useEffect(() => {

  }, []);


  const senNotificacion = () => {
    if(titulo && texto){

      nuevaNotificacionFcm(titulo, texto, imagen).then(
        data => {
          console.log(data);
          if (data != null) {
            //setData(data); 
          }
        }
      )
        return "";
    }

    toastError("LLena el formulario con los datos necesarios");
  }

  const senNotificacionPersonal = () => {

    if(titulo && texto && token){
    nuevaNotificacionPersonalFcm(titulo, texto, imagen,token).then(
      data => {
        console.log(data);
        if (data != null) {
          //setData(data); 
        }
      }
    )
    return "";
  }

    
    toastError("LLena el formulario con los datos necesarios");

  }


  return (
    <div className="adminAllLinks page">
      <div className="main-content-container container-fluid px-4">
        <div className="page-header row no-gutters py-4">
          <div className="col-12 col-sm-4 text-start text-sm-left mb-0">
            <span className="text-uppercase page-subtitle">Administración</span>
            <h3 className="page-title">Notificaciones FCM</h3>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="mb-4">
              <div className="card-header border-bottom pb-3">
                <div className="row justify-content-between">
                  <div className="col-6">
                    <h6 className="m-0">Genera una Nueva Notificación</h6>
                  </div>
                  <div className="col-6 text-end">
                  </div>
                </div>
              </div>
              <div className="card-body p-0 pb-3 text-center">

                <div className="row mt-3 p-3">
                  <div className="col-6 text-start">

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                      <Form.Label><strong>Título de la notificación</strong></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={titulo}
                        onChange={(e) => setTitulo(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label><strong>Texto de la notificación</strong></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={texto}
                        onChange={(e) => setTexto(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                      <Form.Label><strong>Imagen de notificación (opcional)</strong></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="https://miurl/imagen.jpg"
                        value={imagen}
                        onChange={(e) => setImagen(e.target.value)}
                      />
                    </Form.Group>
                    <div className="text-center mt-4">
                      <Button variant="primary" onClick={(e) => senNotificacion()}>Enviar Notificación</Button>
                    </div>

                  </div>

                  <div className="col-6 text-start">
                    
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                      <Form.Label><strong>Token Personal</strong></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="..."
                        value={token}
                        onChange={(e) => setToken(e.target.value)}
                      />
                    </Form.Group>


                    <div className="text-center mt-3">
                      <Button variant="outline-primary" onClick={(e) => senNotificacionPersonal()}>Enviar Notificación Personal</Button>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}


export default AdminSendNotificationApp;