import axios from "axios";
import { toastError } from "../alerts/toasAlerts";
import { infoCodTK } from "../functions/infoTk";
import { verificarCode } from "../functions/verificResponse"
import { add_pharma_turno_url, delete_pharma_turno_url, list_event_pharmas_mes_url } from "../config/urls/urlFarmacia";
import { add_glp_turno_url, delete_glp_turno_url, list_event_glp_mes_url } from "../config/urls/urlCronogramaGlp";

export async function ListaFarmaciasEventMes(mes) {

    return axios.post(list_event_pharmas_mes_url,{mes} ,{
      headers: {
        'Authorization': `Bearer ${infoCodTK()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      //console.log(data.data);
      if (data.data.ok && data.status === 200) {
        return data.data.data;
      } else {
        toastError(data.data.mensaje);
        verificarCode(data.data.status);
        console.log("Conexion fallida");
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      //mostrarAlerta("Ocurrió un error al conectar con el servidor");
    });
  
  }


  
export async function newFarmaciaTurno(dia,code) {

  return axios.post(add_pharma_turno_url,{dia,code} ,{
    headers: {
      'Authorization': `Bearer ${infoCodTK()}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
    }
  }).then(data => {
    //console.log(data.data);
    if (data.data.ok && data.status === 200) {
      return data.data.data;
    } else {
      toastError(data.data.mensaje);
      verificarCode(data.data.status);
      console.log("Conexion fallida");
      return null;
    }
  })
  .catch(err => {
    console.log(err);
    //mostrarAlerta("Ocurrió un error al conectar con el servidor");
  });

}
  

export async function deleteFarmaciaTurno(code) {

  return axios.post(delete_pharma_turno_url,{code} ,{
    headers: {
      'Authorization': `Bearer ${infoCodTK()}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
    }
  }).then(data => {
    //console.log(data.data);
    if (data.data.ok && data.status === 200) {
      return data.data.data;
    } else {
      toastError(data.data.mensaje);
      verificarCode(data.data.status);
      console.log("Conexion fallida");
      return null;
    }
  })
  .catch(err => {
    console.log(err);
    //mostrarAlerta("Ocurrió un error al conectar con el servidor");
  });

}


export async function ListaCronogramaGlpEventMes(mes) {

  return axios.post(list_event_glp_mes_url,{mes} ,{
    headers: {
      'Authorization': `Bearer ${infoCodTK()}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
    }
  }).then(data => {
    //console.log(data.data);
    if (data.data.ok && data.status === 200) {
      return data.data.data;
    } else {
      toastError(data.data.mensaje);
      verificarCode(data.data.status);
      console.log("Conexion fallida");
      return null;
    }
  })
  .catch(err => {
    console.log(err);
    //mostrarAlerta("Ocurrió un error al conectar con el servidor");
  });

}



export async function newGlpTurno(ubic,dia,hora) {

  return axios.post(add_glp_turno_url,{ubic,dia,hora} ,{
    headers: {
      'Authorization': `Bearer ${infoCodTK()}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
    }
  }).then(data => {
    //console.log(data.data);
    if (data.data.ok && data.status === 200) {
      return data.data.data;
    } else {
      toastError(data.data.mensaje);
      verificarCode(data.data.status);
      console.log("Conexion fallida");
      return null;
    }
  })
  .catch(err => {
    console.log(err);
    //mostrarAlerta("Ocurrió un error al conectar con el servidor");
  });

}
  

export async function deleteGlpTurno(code) {

  return axios.post(delete_glp_turno_url,{code} ,{
    headers: {
      'Authorization': `Bearer ${infoCodTK()}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
    }
  }).then(data => {
    //console.log(data.data);
    if (data.data.ok && data.status === 200) {
      return data.data.data;
    } else {
      toastError(data.data.mensaje);
      verificarCode(data.data.status);
      console.log("Conexion fallida");
      return null;
    }
  })
  .catch(err => {
    console.log(err);
    //mostrarAlerta("Ocurrió un error al conectar con el servidor");
  });

}
