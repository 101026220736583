import { useEffect, useState } from "react";
import { httpGet, httpPost } from "../../../http/request";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { toastError, toastSuccess } from "../../../alerts/toasAlerts";
import { PropaneSharp } from "@mui/icons-material";

const AdminAllBuss = () => {
  const [modalEdit, setModalEdith] = useState({
    show: false,
    code: 0,
    data: {},
  });
  const [modalCat, setModalCat] = useState({
    show: false,
    code: 0,
    data: {},
  });
  const [buss, setBuss] = useState([]);

  const loadBusses = () => {
    httpGet("v1/adm/business/listarTodas").then((data) => {
      console.log(data);
      if (data != null) {
        setBuss(data);
      }
    });
  };

  useEffect(() => {
    loadBusses();
  }, []);

  const listItems = buss.map((d, index) => (
    <tr key={index.toString()}>
      <td>{index + 1}</td>
      <td className="text-start">
        <p className="fw-bold">{d.nombre ?? ""}</p>
        <p className="text-sm text-secondary">{d.subcategorias ?? ""}</p>
      </td>
      <td>{d.codigo ?? ""}</td>
      <td>{(d.estado ?? "B") === "A" ? "Activo" : "No Activo"}</td>
      <td>
        <Button
          size="sm"
          className="mx-1"
          variant="outline-primary"
          onClick={() => setModalEdith({ show: true, code: d.code, data: d })}
        >
          Detalles
        </Button>
        <Button
          size="sm"
          className="mx-1"
          variant="outline-info"
          onClick={() => setModalCat({ show: true, code: d.code, data: d })}
        >
          Categorias
        </Button>
      </td>
    </tr>
  ));

  return (
    <div className="adminAllLinks page">
      <div className="main-content-container container-fluid px-4">
        <div className="page-header row no-gutters py-4">
          <div className="col-12 col-sm-4 text-start text-sm-left mb-0">
            <span className="text-uppercase page-subtitle">Administración</span>
            <h3 className="page-title">Negocios</h3>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="mb-4">
              <div className="card-header border-bottom pb-3">
                <div className="row justify-content-between">
                  <div className="col-6">
                    <h6 className="m-0">Lista de Negocios registrados</h6>
                  </div>
                  <div className="col-6 text-end">
                    <Button
                      size="sm"
                      className="mx-1"
                      variant="secondary"
                      onClick={() => loadBusses()}
                    >
                      Actualizar Lista
                    </Button>
                    <Button
                      size="sm"
                      className="mx-1"
                      onClick={() => setModalEdith({ show: true, data: {} })}
                    >
                      + Agregar Nuevo
                    </Button>
                  </div>
                </div>
              </div>
              <div className="card-body p-0 pb-3 text-center">
                <table className="table mb-0 table-hover">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        #
                      </th>
                      <th scope="col" className="border-0">
                        Nombre
                      </th>
                      <th scope="col" className="border-0">
                        Codigo
                      </th>
                      <th scope="col" className="border-0">
                        Estado
                      </th>
                      <th scope="col" className="border-0"></th>
                    </tr>
                  </thead>
                  <tbody>{listItems}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalEditBuss
        show={modalEdit.show}
        onHide={() => setModalEdith({ show: false, code: 0 })}
        onSuccess={() => loadBusses()}
        data={modalEdit.data ?? {}}
      />

      <ModalEditCatBuss
        show={modalCat.show}
        onHide={() => setModalCat({ show: false, code: 0 })}
        //onSuccess={() => loadBusses()}
        data={modalCat.data ?? {}}
      />
    </div>
  );
};

export default AdminAllBuss;

function ModalEditBuss(props) {
  const [data, setData] = useState({});

  function cargarDatos(code) {
    httpPost("v1/adm/business/detalleCode", { code }).then((d) => {
      if (d) {
        setData(d);
      }
    });
  }

  useEffect(() => {
    if (props.show && props.data.code > 0) {
      cargarDatos(props.data.code);
    }
  }, [props]);

  function limpiarYsalir() {
    setData({});
    props.onHide();
  }

  function guardar(data) {
    if (!data.nombre && !data.codigo && !data.direccion && !data.estado) {
      toastError("Debe ingresar todos los datos obligatorios");
      return;
    }
    if (props.data.code > 0) {
      httpPost("v1/adm/business/updateBuss", data).then((d) => {
        if (d) {
          props.onSuccess();
          limpiarYsalir();
        }
      });
    } else {
      httpPost("v1/adm/business/createBuss", data).then((d) => {
        if (d) {
          props.onSuccess();
          limpiarYsalir();
        }
      });
    }
  }

  return (
    <div className="modal_upInfo">
      <Modal
        show={props.show}
        onHide={props.onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Form>
          <Modal.Header>
            <Modal.Title className="col-12">
              <p className="text-center">Editar {props.data.nombre ?? ""}</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={12} md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput3"
                >
                  <Form.Label>
                    <strong>Nombre del Negocio</strong>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={data.nombre}
                    onChange={(e) =>
                      setData({ ...data, nombre: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={3}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput3"
                >
                  <Form.Label>
                    <strong>Código</strong>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={data.codigo}
                    onChange={(e) =>
                      setData({ ...data, codigo: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>

              <Col sm={12} md={3}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput3"
                >
                  <Form.Label>
                    <strong>Estado</strong>
                  </Form.Label>
                  <select
                    class="form-select"
                    aria-label=""
                    value={data.estado}
                    onChange={(e) =>
                      setData({ ...data, estado: e.target.value })
                    }
                  >
                    <option value="">Selecciona una Opción</option>
                    <option value="A">Activo</option>
                    <option value="B">No Activo</option>
                  </select>
                </Form.Group>
              </Col>
              <Col sm={12} md={12}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput3"
                >
                  <Form.Label>
                    <strong>Dirección</strong>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={data.direccion}
                    onChange={(e) =>
                      setData({ ...data, direccion: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>

              <Col sm={12} md={12}>
                <strong>Geolocalización:</strong>
                <Row>
                  <Col sm={4}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label>
                        <strong>Longitud</strong>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={data.lng}
                        onChange={(e) =>
                          setData({ ...data, lng: e.target.value })
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label>
                        <strong>Latitud</strong>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={data.lat}
                        onChange={(e) =>
                          setData({ ...data, lat: e.target.value })
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4} className="d-flex">
                    {data.urlMaps ?? false ? (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a
                        href={data.urlMaps}
                        target="_blank"
                        rel="noreferrer"
                        className="btn btn-primary btn-sm m-auto"
                      >
                        Ver en mapa
                      </a>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </Col>

              <Col sm={12} md={12}>
                <strong>Google Maps:</strong>
                <Row>
                  <Col sm={8}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label>
                        <strong>Link googleMaps</strong>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={data.maps}
                        onChange={(e) =>
                          setData({ ...data, maps: e.target.value })
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4} className="d-flex">
                    {data.maps ?? false ? (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a
                        href={data.urlMaps}
                        target="_blank"
                        rel="noreferrer"
                        className="btn btn-primary btn-sm m-auto"
                      >
                        Ver en GoogleMaps
                      </a>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer className="p-0">
            <div className="row col-12 m-0">
              <button
                type="button"
                onClick={limpiarYsalir}
                className="col-6 btn-ios rounded-bottom"
              >
                Cancelar
              </button>
              <button
                type="button"
                onClick={() => guardar(data)}
                className="col-6 border-start btn-ios rounded-bottom"
              >
                Guardar
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}

function ModalEditCatBuss(props) {
  const [data, setData] = useState([]);
  const [dataCats, setDataCats] = useState([]);

  const [selectCat, setSelectCat] = useState(0);

  function cargarDatos(code) {
    httpPost("v1/adm/business/subCategoriasDeBuss", { code }).then((d) => {
      if (d) {
        setData(d.lista ?? []);
      }
    });
  }

  function cargarSubCats() {
    httpGet("v1/adm/subcategoria/listaTodas").then((d) => {
      if (d) {
        setDataCats(d.lista ?? []);
      }
    });
  }

  useEffect(() => {
    if (props.show && props.data.code > 0) {
      cargarDatos(props.data.code);
      cargarSubCats();
    }
  }, [props]);

  function limpiarYsalir() {
    //setData({});
    props.onHide();
  }

  const agregarCat = (codeSub) => {
    if (!codeSub || codeSub === 0 || codeSub === "0") {
      toastError("Seleccione una opción");
      return;
    }
    httpPost("v1/adm/business/addSubCategoria", {
      code: props.data.code,
      codesub: codeSub,
    }).then((d) => {
      if (d) {
        cargarDatos(props.data.code);
      }
    });
  };

  const quitarSubCat = (code) => {
    httpPost("v1/adm/business/quitarSubCategoria", { code }).then((d) => {
      if (d) {
        cargarDatos(props.data.code);
      }
    });
  };

  return (
    <div className="modal_upInfo">
      <Modal
        show={props.show}
        onHide={props.onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Form>
          <Modal.Header>
            <Modal.Title className="col-12">
              <p className="text-center">
                Categorias de {props.data.nombre ?? ""}
              </p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={12} md={12}>
                <div className="d-flex  mt-4">
                  <p className="h6">SubCategorias</p>
                  <div className="ms-auto">
                    <div class="input-group">
                      <select
                        class="form-select form-select-sm"
                        id="inputGroupSelect04"
                        aria-label="Example select with button addon"
                        value={selectCat}
                        onChange={(e) => setSelectCat(e.target.value)}
                      >
                        <option value={0}>Selecciona una categoría...</option>
                        {dataCats.map((d, i) => (
                          <option key={i} value={d.code}>
                            <strong>{d.nombre}</strong> - {d.categoria}
                          </option>
                        ))}
                      </select>
                      <button
                        class="btn btn-outline-secondary btn-sm"
                        type="button"
                        onClick={() => {
                          agregarCat(selectCat);
                        }}
                      >
                        Agregar +
                      </button>
                    </div>
                  </div>
                </div>
                <Row>
                  {data.map((d, i) => (
                    <Col key={i} sm={3}>
                      <div className="card">
                        <img
                          className="card-img-top img-fluid border-bottom"
                          src={d.icono}
                          alt="imagenicono"
                          style={{ objectFit: "none" }}
                        />
                        <div className="card-body">
                          <h4 className="card-title">{d.subcategoria}</h4>
                          <h6 className="card-title">{d.categoria}</h6>
                          <div className="text-end">
                            <button
                              className="btn btn-danger btn-sm"
                              type="button"
                              onClick={() => quitarSubCat(d.code)}
                            >
                              Quitar
                            </button>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
                  {/*<Col sm={3}>
                    <div className="card">
                      <img
                        className="card-img-top img-fluid"
                        src="/assets/images/small/img-1.jpg"
                        alt="Card image cap"
                      />
                      <div className="card-body">
                        <h4 className="card-title">Card title</h4>
                        <p className="card-text">
                          Some quick example text to build on the card title and
                          make up the bulk of the card's content.
                        </p>
                        <button className="btn btn-primary btn-sm">Ok</button>
                      </div>
                    </div>
                  </Col>*/}
                </Row>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer className="p-0">
            <div className="row col-12 m-0">
              <button
                type="button"
                onClick={limpiarYsalir}
                className="col-12 btn-ios rounded-bottom"
              >
                Cerrar
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}
