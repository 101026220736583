import { useState } from 'react';


export function useToken() {
    const getToken = () => {
      const tokenString = localStorage.getItem('TkSigse01');
      if(tokenString==="" || tokenString===null){
        return "";
      }
      const userToken = JSON.parse(tokenString);
      return userToken
      
    };
    
    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        localStorage.setItem('TkSigse01', JSON.stringify(userToken));
        setToken(userToken);
      };

      return {
        setToken: saveToken,
        token
      }
  }
